import React, { useState, useEffect, useRef } from "react";
import Container from '@mui/material/Container';
import PropertyDescriptionBook from "../../components/Property/propertyDescriptionBook";
import Button from '@mui/material/Button';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Slider from 'react-slick';
import Room1 from "../../assets/images/Rectangle 62.png";
// import Room2 from "../../assets/images/Rectangle 63.png";
// import Room3 from "../../assets/images/Rectangle 64.png";
// import Room4 from "../../assets/images/Rectangle 65.png";
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import { useNavigate, useLocation } from 'react-router-dom';
import { HouseRuleModal } from "../../components/Property/houseRuleModal";
import { GLOBAL_SETTINGS_KEY } from "../../common/constant";
import { InterestedTenantModal } from "../../components/Property/interestedTenantModal";
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';

export const BookRoom = () => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const [isInterestedModal, setIsInterestedModal] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [roomData, setRoomData] = useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [globalData, setGlobalData] = useState({
        deposit_amount: '',
        platform_fee: '',
        pre_booking: ''
    })

    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);
    }, []);

    useEffect(() => {
        if (location?.state?.roomData) {
            const result = JSON.parse(location?.state?.roomData)
            result?.basicDetails?.global_data?.forEach((item) => {
                if (item.name === GLOBAL_SETTINGS_KEY.DEPOSIT_AMOUNT) {
                    setGlobalData((pre) => {
                        return {
                            ...pre,
                            deposit_amount: item
                        }
                    })
                }
                if (item.name === GLOBAL_SETTINGS_KEY.PLATFORM_FEE) {
                    setGlobalData((pre) => {
                        return {
                            ...pre,
                            platform_fee: item
                        }
                    })
                }
                if (item.name === GLOBAL_SETTINGS_KEY.PRE_BOOKING_DAYS) {
                    setGlobalData((pre) => {
                        return {
                            ...pre,
                            pre_booking: item
                        }
                    })
                }
            })
            setRoomData(result)
        }
    }, [location?.state?.roomData])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const settingsNav2 = {
        slidesToShow : roomData?.pictures.length < 4 ? roomData?.pictures.length : 4,
        swipeToSlide: false,
        focusOnSelect: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    const navigate = useNavigate();

    const proceedToBook = (propertyDetails) => {
        setIsInterestedModal(true, { state: { propertyDetails } })
        // navigate('/book-form', { state: { propertyDetails } });        
    }


    return (
        <div className='landing_pg detail_pg'>
            <section className="available_rooms_listing">
                <Container>
                    <div className='available_room_detail'>
                        <PropertyDescriptionBook propertyDetails={roomData?.basicDetails} />
                        <div className='bed_matrix_block book_bed_matrix_block'>
                            <div className='d-flex align-items-center justify-content-between bed_mat_whole'>
                                <div className="page_header main_bed_header">
                                    <span className="bed_heading">Bed Details</span> 
                                    <span className='bed_availability theme_color my-2' style={{ fontSize: '18px' }}> &nbsp;Bed will be available from 31 October</span>
                                </div>
                                {roomData?.basicDetails?.rules?.length > 0 && (
                                    <Button className='theme_button sign_in_button' variant="contained" style={{ zIndex: 0 }} onClick={handleOpen}><RemoveRedEyeOutlinedIcon className='me-2' />House Rule</Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="property_listing">
                        <div className="property_card">
                            <div className="single_property_card">
                                <div className="slider-container property_img">
                                    {roomData?.pictures?.length === 0 && (
                                        <img src={Room1} alt={Room1} style={{ width: '100%', height: '350px', objectFit: 'cover', borderRadius: '3px' }} />
                                    )}
                                    {roomData?.pictures?.length === 1 ? (
                                        <img src={roomData?.pictures[0].location} alt={roomData?.pictures[0].name} style={{ width: '100%', height: '300px', objectFit: 'cover', borderRadius: '8px' }} />
                                    ) : (
                                        <>
                                            <Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)} arrows={false} autoplay={false} autoplaySpeed={3000}>
                                                {roomData?.pictures?.map((image, index) => (
                                                    <div key={index}>
                                                        <img src={image.location} alt={image.name} style={{ width: '100%', height: '350px', borderRadius: '8px', objectFit: 'cover' }} />
                                                    </div>
                                                ))}
                                            </Slider>
                                            <Slider asNavFor={nav1} ref={slider => (sliderRef2 = slider)} {...settingsNav2}>
                                                {roomData?.pictures.map((image, index) => (
                                                    <div key={index}>
                                                        <img src={image.location} alt={image.name} style={{ width: '100%', height: '80px', objectFit:'cover' }} />
                                                    </div>
                                                ))}
                                            </Slider>
                                        </>
                                    )}
                                </div>
                                <div className="d-flex flex-column">
                                    <ul className="bedroom_details list-inline d-flex flex-wrap mb-2">
                                        <li><CorporateFareOutlinedIcon /> &nbsp;Floor No. <span>{roomData?.bed?.floorNo}</span></li>
                                        {roomData?.bed?.flatNo && <li><BusinessOutlinedIcon /> &nbsp;Flat No. <span>{roomData?.bed?.flatNo}</span></li>}
                                        <li><OtherHousesOutlinedIcon /> &nbsp;Room No. <span>{roomData?.bed?.roomNo}</span></li>
                                        <li><HotelOutlinedIcon /> &nbsp;Bed No. <span>{roomData?.bed?.bed?.name}</span></li>
                                    </ul>
                                    <div className="price_detail_card w-100">
                                        <div className="price_detail_header payment_area">Price Details</div>
                                        <ul className="price_detail_body payment_area">
                                            <li className="d-flex justify-content-between">Type <span className="price_amt">{roomData?.room_type}</span></li>
                                            <li className="d-flex justify-content-between">{globalData?.deposit_amount?.display_name}<span className="price_amt">₹ {globalData?.deposit_amount?.payload?.value}/-</span></li>
                                            <li className="d-flex justify-content-between rent_amt">
                                                <span>Rent Amount <InfoOutlinedIcon onClick={handleClick} style={{ fontSize: '15px', cursor: 'pointer' }} /></span>
                                                <span className="price_amt">₹ {roomData?.bed?.bed?.price}/-</span>
                                            </li>
                                            <li className="d-flex justify-content-between">{globalData?.platform_fee?.display_name} <span className="price_amt">₹ {globalData?.platform_fee?.payload?.value}/-</span></li>
                                            <li className="d-flex justify-content-between total_amt">
                                                <div className="total_payable_amount">
                                                    <h5>Total Payable amount for booking</h5>
                                                    <span>Including the deposit and agreement charges </span>
                                                </div>
                                                <span className="price_amt">₹ {parseInt(roomData?.bed?.bed?.price) + parseInt(globalData?.deposit_amount?.payload?.value) + parseInt(globalData?.platform_fee?.payload?.value)}/-</span>
                                            </li>
                                        </ul>
                                        <div className="payment_btn payment_area mb-2">
                                            <Button className='theme_button sign_in_button' variant="contained" onClick={() => proceedToBook(roomData?.basicDetails)}>Proceed to Book</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            {open && (
                <HouseRuleModal open={open} handleClose={handleClose} rules={roomData?.basicDetails?.rules} />
            )}

            {isInterestedModal && (
                <InterestedTenantModal isOpen={isInterestedModal} handleClose={() => setIsInterestedModal(false)} bedDetails={{
                    bedType: roomData?.room_type,
                    propertyName: roomData?.basicDetails?.name
                }} />
            )}

            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className="rent_amt"
            >
                <Typography sx={{ p: 2 }}>Rent will be applicable
                    from the 1st entry date</Typography>
            </Popover>
        </div>

    )
}