import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import * as Yup from 'yup';
import { Card, Grid, Button, Box, TextField } from '@mui/material';
import { EnlistRegistration } from "../../components/Common/EnlistRegistration";
import { useLocation, useNavigate } from 'react-router-dom';
import { MuiOtpInput } from "mui-one-time-password-input";
import { toast } from "react-toastify";
import { handleKeyDown } from "../../helpers/string_helper";
import CountdownTimer from "../../components/Common/CountdownTimer";
import { resendOTP, verifyOTP } from "../../api/owner";
import { useFormik } from "formik";
import { AsterikLabel } from "../../components/Common/AsterikLabel";

export const OtpIndex = () => {
  const [otp, setOtp] = React.useState('');

  const navigate = useNavigate();
  const stateData = useLocation().state;
  const [isLoading, setIsloading] = useState(false)
  const [isValidOTP, setIsValidOTP] = useState(false);
  const [invalidOTPerror, setInvalidOTPerror] = useState(null);
  const [otpExpiresAt] = useState(stateData?.otp?.expiresAt);
  const [resendTime, setResendTime] = useState(30);
  const [disableResend, setDisableResend] = useState(true);
  const passwordPattern = /^(?!.*[\s])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,16}$/;

  const verifyOTPHandler = async (payload) => {
    try {
      const response = await verifyOTP({
        ...payload,
        ...(stateData.user.email
          ? {
            isEmail: true,
            email: stateData?.user?.email,
          }
          : {
            isEmail: false,
            contact_number: stateData?.user?.contact_number,
          }
        )
      });
      if (response?.data?.data?.token) {
        setIsValidOTP(true);
        setInvalidOTPerror('');
        localStorage.setItem('token-owner', response?.data?.data?.token);

        navigate("/property-request", {
          state: {
            user: {
              ...stateData.user,
            },
            token: response?.data?.data?.token,
          },
        });
      }
      toast.success(response?.data?.message);
    } catch (e) {
      setIsValidOTP(false)
      // if (e?.response?.data?.message) toast.error(e?.response?.data?.message);
      setInvalidOTPerror(e?.response?.data?.message);
    }
  }

  const sendOTPHandler = async (ownerData) => {
    try {
      const response = await resendOTP(ownerData)
      if (response.message) {
        setIsValidOTP(true);
        setInvalidOTPerror('');
      }
      toast.success(response?.data?.message);
    } catch (e) {
      setIsValidOTP(false)
      // if (e?.response?.data?.message) toast.error(e?.response?.data?.message);
      setInvalidOTPerror(e?.response?.data?.message);
    }
  }

  const verificationSchema = {
    otp: '',
    password: '',
    confirm_password: '',
  };

  const verificationValidation = Yup.object().shape({
    otp: Yup.string().matches(/^[0-9]{6}$/, 'OTP must be 6 digits').required(),
    password: Yup.string().required('Please Enter Your Password').matches(passwordPattern, "Password should be 8 character long with maximum 16 character of one capital one small one digit and one special case without whitespace"),
    confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Both Passwords must match').required(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: verificationSchema,
    validationSchema: verificationValidation,
    validateOnMount: true,
    onSubmit: async (ownerData) => {
      setIsloading(true);
      await verifyOTPHandler(ownerData)
      setIsloading(false)
    },
  });

  useEffect(() => {
    // If not redirected from signUp page then redirect to home/login page
    if (!stateData?.user) navigate('/')
  }, [stateData]);

  return (
    <div className='manage_enlist_property'>
      <section>
        <Container>
          <div className="property_details">
            <EnlistRegistration />
            <div className="property_form_section">
              <Card className="p-3">
                <h2>Manage your properties, <span>maximize your profits.</span></h2>
                <h5>Enter OTP, sent to your email and register mobile no.</h5>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                      }}
                      className="otp_box"
                    >
                      <MuiOtpInput

                        TextFieldsProps={{
                          size: 'small',
                          sx: { maxWidth: '50px' },
                          disabled: !otpExpiresAt,
                        }}
                        value={otp}
                        onChange={(otp) => {
                          setOtp(otp);
                        }}
                        onBlur={formik.handleBlur}
                        onComplete={(otp) => formik.setFieldValue('otp', otp)}
                        onKeyDown={(e) => { handleKeyDown(e) }}
                        length={6}
                        disabled
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid className="mt-2" container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      name="password"
                      value={formik.values.password}
                      type="password"
                      placeholder="New Password"
                      label={<div>New Password<AsterikLabel /></div>}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      error={
                        !!(formik.touched?.password && formik.errors?.password)
                      }
                      helperText={
                        formik.touched?.password && formik.errors?.password
                          ? formik.errors?.password
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      name="confirm_password"
                      value={formik.values.confirm_password}
                      type="password"
                      placeholder="Confirm Password"
                      label={<div>Confirm Password<AsterikLabel /></div>}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      error={
                        !!(formik.touched?.confirm_password && formik.errors?.confirm_password)
                      }
                      helperText={
                        formik.touched?.confirm_password && formik.errors?.confirm_password
                          ? formik.errors?.confirm_password
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
                <div className="payment_btn payment_area book_payment_btn mt-3 d-flex justify-content-between align-items-center">
                  <span className="d-flex">
                    {(<Button
                      disabled={!formik.isValid || isLoading}
                      className={`${formik.isValid ? 'theme_button sign_in_button' : 'disabled'} me-2`}
                      variant="contained"
                      onClick={formik.handleSubmit}
                      size='small'
                    >
                      Sign Up
                    </Button>)}
                    {otpExpiresAt && (<Button
                      className={`${!disableResend ? 'yellow_gradient_btn' : 'disabled'} sign_in_button `}
                      variant="contained"
                      disabled={disableResend || isLoading}
                      size='small'
                      onClick={(e) => {
                        setResendTime(30);
                        setDisableResend(true);
                        sendOTPHandler({
                          ...(stateData.user.email
                            ? {
                              isEmail: true,
                              email: stateData?.user?.email,
                            }
                            : {
                              isEmail: true,
                              contact_number: stateData?.user?.contact_number,
                            }
                          )
                        })
                      }}
                    >
                      RESEND
                    </Button>)}
                  </span>
                  <p className="mt-2 mb-0 body_font_md me-1">
                    <span className='mt-2'>
                      {(otpExpiresAt && resendTime && !isValidOTP) ?
                        <>
                          Haven’t received the OTP yet? Try to resend in <CountdownTimer seconds={resendTime} onComplete={() => {
                            setDisableResend(false);
                            setResendTime(0);
                          }} />
                          <p className='error'>{invalidOTPerror}</p>
                        </>
                        :
                        <p className='error'> {invalidOTPerror}</p>
                      }
                    </span>
                  </p>
                </div>
              </Card>
            </div>
          </div>
        </Container>
      </section>
    </div>
  )
}