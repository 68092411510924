import React, { useState } from "react";
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import SuitableTags from "../Common/suitableTags";
import Amenities from "./amenities";
import AddressComponent from "../Common/Address";
import { FoodMenuDocumentSlider } from "./foodMenuSlider";
import SuitableTagsDesc from "../Common/suitableTagsDescription";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate } from 'react-router-dom';
import AmenitiesBook from "./amenitiesBook";

const PropertyDescriptionBook = ({ propertyDetails }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isFoodMenu, setIsFoodMenu] = useState(false);
    const [foodMenuFiles, setFoodMenuFiles] = useState();
    const navigate = useNavigate();

    const toggleFoodMenu = () => {
        setIsFoodMenu(!isFoodMenu)
    }

    const handleHiddenList = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div>
            <div className="propertyDesc d-flex align-items-start">
                <ArrowBackOutlinedIcon className="me-2" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}/> 
                <div className="content-container property_content">
                    <div className="property_header propDescBookHeader d-flex justify-content-between align-items-start">
                        <div className='page_header'>
                            <h2>{propertyDetails?.name}</h2>
                        </div>
                        {propertyDetails?.near_to && (
                            <div className="tags_block d-flex align-items-center">
                                <div className="tag_heading" style={{ width: '140px'}}>
                                    <MyLocationOutlinedIcon className="main_icon theme_color" />
                                    <span className="body_font_md font_bold font_black" style={{ width: '140px'}}>Suitable For</span>
                                </div>
                                <SuitableTagsDesc tags={propertyDetails?.near_to ? propertyDetails?.near_to?.split(',') : []} onClick={handleHiddenList} />
                            </div>
                        )}
                    </div>
                    <div className="main_desc">
                        <div className="det_desc main_location d-flex mb-2">
                            <RoomOutlinedIcon className="main_icon" />
                            <p className="body_font_md">
                                <AddressComponent address={propertyDetails?.address} /> <a href={`https://www.google.com/maps/dir/?api=1&destination=${propertyDetails?.latitude},${propertyDetails?.longitude}&dir_action=navigate`} target="_blank" rel="noreferrer">View in Map</a>
                            </p>
                        </div>
                        <div className="det_desc food_service d-flex">
                            <RestaurantOutlinedIcon className="main_icon" />
                            {propertyDetails?.food_menu_documents?.length > 0 ? (
                                <p className="body_font_md">
                                    Food Service Available <span className="view-menu-button" onClick={() => {
                                        setFoodMenuFiles(propertyDetails?.food_menu_documents)
                                        toggleFoodMenu()
                                    }}>{'View menu card'}</span>
                                </p>
                            ) : (
                                <p className="body_font_md">
                                    Food Service Not Available
                                </p>
                            )}

                        </div>
                    </div>
                </div>
                

                {isFoodMenu && <FoodMenuDocumentSlider isOpen={isFoodMenu} handleClose={toggleFoodMenu} files={foodMenuFiles} />}
            </div>
            {propertyDetails?.amenities?.length > 0 && (
                <div className="amenities propDescBookAmenities d-flex align-items-center">
                    <div className='page_header'>
                        <h2 className="m-0">Amenities</h2>
                    </div>
                    <div className="tags_block d-flex align-items-center">
                        <AmenitiesBook tags={propertyDetails?.amenities?.length > 0 ? propertyDetails?.amenities : []} onClick={handleHiddenList} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PropertyDescriptionBook;
