import React, { useState } from 'react';
import { Container, Card, TextField, Grid, InputLabel } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { FormControlLabel } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate } from 'react-router-dom';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
// import SuitableTagsDesc from "../Common/suitableTagsDescription";
import SuitableTagsDesc from "../../components/Common/suitableTagsDescription";
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import AddressComponent from "../../components/Common/Address";
import { useLocation } from 'react-router-dom';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const FileButton = styled(Button)({

    backgroundColor: '#ECECEC',
    alignItems: 'center',
    color: '#565656',
    border: "1px solid #B1B1B1",
    '&:hover': {
        backgroundColor: '#ECECEC',
        color: '#565656',
        border: '1px solid #B1B1B1',
    },
});
const tenantType = [
    {
      value: 'Student',
      label: 'Student',
    },
    {
      value: 'Professional',
      label: 'Professional',
    },
    {
      value: 'Doctor',
      label: 'Doctor',
    },
  ];

const BookForm = () => {
    const location = useLocation();
    const propertyDetails = location.state?.propertyDetails;
    // console.log("propertyDetails", propertyDetails)
    const [fileName, setFileName] = useState('No file chosen');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isFoodMenu, setIsFoodMenu] = useState(false);
    const [foodMenuFiles, setFoodMenuFiles] = useState();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file ? file.name : 'No file chosen');
    };
    const navigate = useNavigate();

    const handleHiddenList = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const toggleFoodMenu = () => {
        setIsFoodMenu(!isFoodMenu)
    }

    return (
        <div className='detail_pg detail_booking_form'>
            <section className="available_room_detail available_rooms_listing">
                <Container>
                <div className="propertyDesc d-flex align-items-start">
                    <ArrowBackOutlinedIcon className="me-2" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}/> 
                    <div className="content-container property_content">
                        <div className="property_header propDescBookHeader d-flex justify-content-between align-items-start">
                            <div className='page_header'>
                                <h2>{propertyDetails?.name}</h2>
                            </div>
                            {propertyDetails?.near_to && (
                                <div className="tags_block d-flex align-items-center">
                                    <div className="tag_heading" style={{ width: '140px'}}>
                                        <MyLocationOutlinedIcon className="main_icon theme_color" />
                                        <span className="body_font_md font_bold font_black" style={{ width: '140px'}}>Suitable For</span>
                                    </div>
                                    <SuitableTagsDesc tags={propertyDetails?.near_to ? propertyDetails?.near_to?.split(',') : []} onClick={handleHiddenList} />
                                </div>
                            )}
                        </div>
                        <div className="main_desc">
                            <div className="det_desc main_location d-flex mb-2">
                                <RoomOutlinedIcon className="main_icon" />
                                <p className="body_font_md">
                                    <AddressComponent address={propertyDetails?.address} /> <a href={`https://www.google.com/maps/dir/?api=1&destination=${propertyDetails?.latitude},${propertyDetails?.longitude}&dir_action=navigate`} target="_blank" rel="noreferrer">View in Map</a>
                                </p>
                            </div>
                            <div className="det_desc food_service d-flex">
                                <RestaurantOutlinedIcon className="main_icon" />
                                {propertyDetails?.food_menu_documents?.length > 0 ? (
                                    <p className="body_font_md">Food Service Available 
                                    <span className="view-menu-button" onClick={() => { setFoodMenuFiles(propertyDetails?.food_menu_documents)
                                            toggleFoodMenu()
                                        }}>{'View menu card'}</span>
                                    </p>
                                ) : (
                                    <p className="body_font_md">
                                        Food Service Not Available
                                    </p>
                                )}

                            </div>
                        </div>
                        <ul className="bedroom_details list-inline d-flex flex-wrap mb-0">
                            <li><CorporateFareOutlinedIcon /> &nbsp;Floor No. <span>2nd</span></li>
                            <li><BusinessOutlinedIcon /> &nbsp;Flat No. <span>204</span></li>
                            <li><OtherHousesOutlinedIcon /> &nbsp;Room No. <span>5</span></li>
                            <li><HotelOutlinedIcon /> &nbsp;Bed No. <span>3</span></li>
                        </ul>
                    </div>
                </div>
                    <div className='form_details my-3'>
                        <h4>Please fill up the form to proceed with the booking</h4>
                        <Card>    
                            <Grid container spacing={2}>
                                <Grid item md={4} sm={12} className='date_picker_field'>
                                    <InputLabel htmlFor="entry-date">1st Date of Entry</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker placeholder="Select Date" slotProps={{ textField: { size: 'small', fullWidth:'true' } }} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                <InputLabel htmlFor="name">Name</InputLabel>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="Name"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <InputLabel htmlFor="email">Email</InputLabel>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="Email"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <InputLabel htmlFor="phone">Phone</InputLabel>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="Phone"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item md={8} sm={12}>
                                    <InputLabel htmlFor="address">Address</InputLabel>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="Address"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <InputLabel htmlFor="tenantType">Tenant Type</InputLabel>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        defaultValue="Select"
                                        size='small'
                                        fullWidth
                                    >
                                        {tenantType.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <InputLabel htmlFor="adhar">Upload Aadhar Card</InputLabel>
                                    <FileButton
                                        component="label"
                                        variant="contained"
                                        role={undefined}
                                    >
                                        Choose file
                                        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                                    </FileButton>
                                    <span style={{ marginLeft: 16 }}>{fileName}</span>
                                </Grid>
                            </Grid>
                            <div className="payment_btn payment_area book_payment_btn my-3 text-center">
                                <Button style={{ width: '238px'}} className='theme_button sign_in_button' variant="contained" >Book Now</Button>
                            </div>
                        </Card>
                    </div>
                </Container>
            </section>
        </div>
    );
};


export default BookForm;