import React from "react";
import ExactRoom from "../../assets/images/exact_room.svg";
import Refund from "../../assets/images/refund.svg";
import Brokerage from "../../assets/images/no_brokrage.svg";
import WebsiteApp from "../../assets/images/web_app.svg";

const dashInfo = [
    {
        image: ExactRoom,
        text: <h4>Book the exact room of a floor of your choice.</h4>
    },
    {
        image: Refund,
        text: <h4>Guaranteed refund of caution money.</h4>
    },
    {
        image: Brokerage,
        text: <h4>No brokerage fee or hidden charges for bed booking.</h4>
    },
    {
        image: WebsiteApp,
        text: <h4>Manage everything via website / app </h4>
    }
]
const DashboardInfoCards = () => {
    return(
        <div className="home_info_cards">
            {dashInfo.map((item, index) => (
                <div className="single_card" key={index}>
                    <div className="info_bg">
                        <img src={item.image} alt="dash_info" width="100" height="100" />
                    </div>
                    {item.text}
                </div>
            ))}
        </div>
    )
}

export default DashboardInfoCards;