import React from 'react';
import Slider from "react-slick";
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import DoubleQuote from "../../assets/images/double_quote.png";
import ProfilePic from "../../assets/images/profile.png";


const Testimonial = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    };
    
    return (
        <Container>
            <div className='testimonials'>
                <Slider {...settings}>
                    <Card className="single_testimonial">
                        <CardContent className='d-flex'>
                            <Avatar alt={ProfilePic} src={ProfilePic} className="avatar me-3" />
                            <div className='single_testimonial_content'>
                                <Typography variant="body1">
                                    <img src={DoubleQuote} alt={DoubleQuote} />
                                </Typography>
                                <Typography variant="body2" className="name">
                                    Lorem ipsum dolor sit amet consectetur. Adipiscing ut nisi leo nibh eros in. 
                                </Typography>
                                <Typography variant="body2" color="textPrimary" className='client_name'>
                                    Francis Towne
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Student
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className="single_testimonial">
                        <CardContent className='d-flex'>
                            <Avatar alt={ProfilePic} src={ProfilePic} className="avatar me-3" />
                            <div className='single_testimonial_content'>
                                <Typography variant="body1" className="quote">
                                    <img src={DoubleQuote} alt={DoubleQuote} />
                                </Typography>
                                <Typography variant="body2" className="name">
                                    Lorem ipsum dolor sit amet consectetur. Adipiscing ut nisi leo nibh eros in. 
                                </Typography>
                                <Typography variant="body2" color="textPrimary" className='client_name'>
                                    Francis Towne
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Student
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className="single_testimonial">
                        <CardContent className='d-flex'>
                            <Avatar alt={ProfilePic} src={ProfilePic} className="avatar me-3" />
                            <div className='single_testimonial_content'>
                                <Typography variant="body1" className="quote">
                                    <img src={DoubleQuote} alt={DoubleQuote} />
                                </Typography>
                                <Typography variant="body2" className="name">
                                    Lorem ipsum dolor sit amet consectetur. Adipiscing ut nisi leo nibh eros in. 
                                </Typography>
                                <Typography variant="body2" color="textPrimary" className='client_name'>
                                    Francis Towne
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Student
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                </Slider>
            </div>
        </Container>
    )
}

export default Testimonial;