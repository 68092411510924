import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Testimonial from '../../components/Common/testimonial';
import DashboardInfoCards from './dashboardInfo';
import { TypeAnimation } from 'react-type-animation';
import LocationSearchBar from '../../components/Common/locationSearchbar';
import PropertyListing from './propertyListing';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import FormatLineSpacingOutlinedIcon from '@mui/icons-material/FormatLineSpacingOutlined';
import Filter from '../../components/Common/Filter';
import { getAllProperties } from '../../api/property';

export const Dashboard = () => {
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#333333',
  };
  const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E6',
    700: '#0059B3',
    800: '#004C99',
    900: '#003A75',
  };
  const Listbox = styled('ul')(
    ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 200px;
    overflow: auto;
    outline: 0px;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    z-index: 1;
    border: 1px solid #737373;
    border-radius: 10px;
    box-shadow: 0 114px 32px 0 rgba(0, 0, 0, 0), 0 73px 29px 0 rgba(0, 0, 0, 0.01), 0 41px 25px 0 rgba(0, 0, 0, 0.05), 0 18px 18px 0 rgba(0, 0, 0, 0.09), 0 5px 10px 0 rgba(0, 0, 0, 0.10);
    `,
  );
  const MenuItem = styled(BaseMenuItem)(
    ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }
  `,
  );

  const MenuButton = styled(BaseMenuButton)(
    ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
    outline: none;
  }
  `,
  );

  useEffect(() => {
    fetchAllProperty()
  }, [])

  const [propertyList, setPropertyList] = useState([]);
  const [update, setUpdate] = useState(0);
  const [filterData, setFilterData] = useState()

  const fetchAllProperty = async () => {
    try {
      const response = (await getAllProperties()).data.data;
      setPropertyList(response);
    } catch (err) {
      console.log(err)
    }
  }

  const handleSorting = (orderBy) => {
    let result
    if (orderBy === 'asc') {
      result = propertyList.sort((a, b) => parseInt(a.minBedPrice) - parseInt(b.minBedPrice));
    } else {
      result = propertyList.sort((a, b) => parseInt(b.minBedPrice) - parseInt(a.minBedPrice));
    }
    setPropertyList(result);
    setUpdate(update + 1)
  }

  return (
    <div className='landing_pg'>
      {/* Banner section starts here */}
      <section className='home_search_banner'>
        <Container>
          <div className='main_banner_content'>
            <div className='page_sub_heading'>
              <h2>
                {/* Near: AMITY . TCS . Biswa Bangla Gate . HIDCO etc */}
                <TypeAnimation
                  sequence={['150+ Beds', 500, 'Near: AMITY . TCS . Biswa Bangla Gate . HIDCO etc', 500]}
                  style={{ fontSize: '2em' }}
                  repeat={Infinity}
                />
              </h2>
            </div>
            <p className='inner_header'>Stress free rent searching</p>
            <div className='main_banner_search'>
              <LocationSearchBar searchPRopertyData={(data) => {
                setPropertyList(data);
                setUpdate(update + 1)
              }} />
            </div>
          </div>
        </Container>
      </section>
      {/* Dashboard Information cards starts here */}
      <section>
        <Container>
          <DashboardInfoCards />
        </Container>
      </section>
      {/* Available rooms listing starts here */}
      <section className='available_rooms_listing'>
        <Container>
          <div className='page_sub_heading d-flex justify-content-end align-items-end'>
            {/* <h2>Available Rooms from Newtown</h2> */}
            <div className='filters d-flex'>
              <div className='filterBy me-2 mb-3'>
                <Filter emitFilterData={(data) => {
                  setFilterData(data)
                  setUpdate(update + 1)
                }} />
              </div>
              <div className='filterBy me-2'>
                <Dropdown>
                  <MenuButton><FormatLineSpacingOutlinedIcon /><span className='text'>Sort by</span></MenuButton>
                  <Menu slots={{ listbox: Listbox }}>
                    <MenuItem onClick={() => handleSorting('asc')}>Price - Low to High</MenuItem>
                    <MenuItem onClick={() => handleSorting('desc')}>Price - High to Low</MenuItem>
                  </Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <PropertyListing key={update} propertyData={propertyList} filterData={filterData} />
        </Container>
      </section>
      {/* <h2 className="align-items-center">This is dashboardthth</h2> */}
      <section className='testimonial_section'>
        <div className='page_sub_heading'>
          <h2>Testimonials</h2>
        </div>
        <Testimonial />
      </section>
    </div>
  )
}