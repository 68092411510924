import React, { useState } from "react";
import Container from '@mui/material/Container';
import { Card, Grid, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { EnlistRegistration } from "../../components/Common/EnlistRegistration";
import { useNavigate } from 'react-router-dom';
import { signUpOwner } from "../../api/owner";
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { handleKeyDown } from "../../helpers/string_helper";
import { AsterikLabel } from "../../components/Common/AsterikLabel";

export const registerOwnerHandler = async (payload) => {
	try {
		const response = await signUpOwner(payload);
		if (response?.status === 200) {
			toast.success(response?.data?.message);
			return response?.data?.data;
		} else {
			toast.error(response?.data?.message);
		}
	} catch (error) {
		if (error.response?.data?.message) toast.error(error.response?.data?.message);
		else toast.error(error.message);
		console.log(error);
	}
	return false;
};

export const EnlistYourProperty = () => {
	const navigate = useNavigate();
	const [isLoading, setIsloading] = useState(false)

	const propertyOwnerSchema = {
		name: '',
		email: '',
		contact_number: '',
	};

	const propertyOwnerValidation = Yup.object().shape({
		name: Yup.string()
			.strict(true)
			.trim('Must not include leading and trailing spaces')
			.max(100, 'Too Long!')
			.required('Please Enter name'),
		contact_number: Yup.string()
			.strict(true)
			.trim('Must not include leading and trailing spaces')
			.matches(/^[0-9]{10}$/, 'Contact number must be 10 digits')
			.required('Please enter contact number'),
		email: Yup.string()
			.strict(true)
			.trim('Must not include leading and trailing spaces')
			.email()
			.typeError('Please Enter Valid Email')
			.optional(),
	});

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: propertyOwnerSchema,
		validationSchema: propertyOwnerValidation,
		validateOnMount: true,
		onSubmit: async (ownerData) => {
			setIsloading(true);
			try {
				const response = await registerOwnerHandler(ownerData);
				if (response?.user?.id) navigate("/otp-page", {
					state: {
						otp: response?.otp,
						user: {
							...response?.user,
							...ownerData,
						},
					},
				});
			} catch (e) {
				if (e?.response?.data?.message) toast.error(e.response.data.message);
				else toast.error(e.message);
				console.error(e);
			} finally {
				setIsloading(false);
			}
		},
	});

	return (
		<div className='manage_enlist_property'>
			<section>
				<Container>
					<div className="property_details">
						<EnlistRegistration />
						<div className="property_form_section">
							<Card className="p-3">
								<h2>Manage your properties, <span>maximize your profits.</span></h2>
								<h5>Get Started</h5>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<TextField
											name="name"
											placeholder="Name"
											label={<div>Name< AsterikLabel /></div>}
											variant="outlined"
											size="small"
											fullWidth

											className="form-control mb-3"
											type="text"
											disabled={isLoading}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.name}
											required
											error={
												!!(formik.touched?.name && formik.errors?.name)
											}
											helperText={
												formik.touched?.name && formik.errors?.name
													? formik.errors?.name
													: ''
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											name="email"
											placeholder="Email"
											label={<div>Email< AsterikLabel /></div>}
											variant="outlined"
											size="small"
											fullWidth
											className="form-control mb-3"
											type="text"
											disabled={isLoading}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.email}
											error={
												!!(formik.touched?.email && formik.errors?.email)
											}
											helperText={
												formik.touched?.email && formik.errors?.email
													? formik.errors?.email
													: ''
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											name="contact_number"
											placeholder="Phone"
											label={<div>Phone< AsterikLabel /></div>}
											variant="outlined"
											size="small"
											fullWidth
											className="form-control mb-3"
											type="text"
											disabled={isLoading}
											onKeyDown={(e) => { handleKeyDown(e) }}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.contact_number}
											required
											error={
												!!(formik.touched?.contact_number && formik.errors?.contact_number)
											}
											helperText={
												formik.touched?.contact_number && formik.errors?.contact_number
													? formik.errors?.contact_number
													: ''
											}
										/>
									</Grid>
								</Grid>
								<div className="payment_btn payment_area book_payment_btn mt-3">
									<Button
										disabled={!formik.isValid}
										className='theme_button sign_in_button'
										variant="contained"
										onClick={() => formik.handleSubmit()}
									>
										Proceed to Sign Up
									</Button>
									<p className="mt-2 mb-0 body_font_md">Already one of our valuable property owners? Please <a>Sign In</a></p>
								</div>
							</Card>
						</div>
					</div>
				</Container>
			</section>
		</div >
	)
}