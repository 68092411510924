import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import moment from "moment";
import logo from '../../assets/images/logo1.png';
import pdfFooterBack from '../../assets/images/pdfFooterBack.png';
import pdfAddress from '../../assets/images/pdfAddress.png';
import phoneIcon from '../../assets/images/pdfPhone.png';
import globeIcon from '../../assets/images/pdfEmail.png';



const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
    },
    container: {
        marginVertical: 10,
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerData: {
        paddingRight: 10,
        paddingLeft: 10,
        margin: 10,

    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10,
        marginTop: 10,
    },
    logo: {
        width: 150,
    },
    title: {
        fontSize: 14,
        flex: 4,
    },
    colon: {
        fontSize: 14,
        marginHorizontal: 20,
    },
    dataValue: {
        fontSize: 14,
        flex: 4,
        textAlign: 'left',
    },
    footerTitle: {
        fontSize: 14,
        flex: 1,
    },
    footerColon: {
        fontSize: 14,
        marginHorizontal: 20,
    },
    footerDataValue: {
        fontSize: 14,
        flex: 4,
        textAlign: 'left',
    },
    noteField: {
        marginVertical: 10,
        borderWidth: 1,
        borderColor: '#D1D0D0', // Border color
        padding: 5, // Padding inside the box
        height: 140, // Adjust height as needed
        borderRadius: 10
    },
    footerData: {
        borderTop: 1,
        borderColor: '#D1D0D0',
    },
    footer: {
        position: 'relative',
        width: '100%',
        fontSize: 12,
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 'auto',
        alignItems: 'center',
        height: 150
    },
    footerBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%', // Ensure full coverage
        zIndex: -1,
    },
    footerLeft: {
        width: '50%',
        flexDirection: 'column',
        padding: 10,
    },
    footerRight: {
        width: '40%',
        flexDirection: 'column',
        padding: 10,
    },
    footerText: {
        color: '#FFFFFF',
        marginLeft: 5,
        fontSize: 16,
    },
    footerIcon: {
        width: 22,
        height: 22,
        marginRight: 5,
        color: '#FFFFFF',
    },
    footerRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
    },
});

const SchedulePropertyVisitSlipPdf = (props) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{

                    height: '100%',
                }}>
                    <View style={styles.headerContainer}>
                        <View >
                            <Image src={logo} style={styles.logo} />
                        </View>
                    </View>
                    <View style={styles.containerData}>
                        <View style={styles.container}>
                            <Text style={styles.title}>Visitor Name</Text>
                            <Text style={styles.colon}>:</Text>
                            <Text style={styles.dataValue}>{props?.item?.fullname}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Visitor Contact Number</Text>
                            <Text style={styles.colon}>:</Text>
                            <Text style={styles.dataValue}>{props?.item?.phone_no}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Visit  Date and Time</Text>
                            <Text style={styles.colon}>:</Text>
                            <Text style={styles.dataValue}>{moment(props?.item?.visit_datetime).format('LLL')}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Property Name</Text>
                            <Text style={styles.colon}>:</Text>
                            <Text style={styles.dataValue}>{props?.item?.property?.name}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Caretaker Name</Text>
                            <Text style={styles.colon}>:</Text>
                            <Text style={styles.dataValue}>{props?.item?.property?.care_taker_name}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Caretaker Contact Number</Text>
                            <Text style={styles.colon}>:</Text>
                            <Text style={styles.dataValue}>{props?.item?.property?.care_taker_contact_no}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Property Manger Name</Text>
                            <Text style={styles.colon}>:</Text>
                            <Text style={styles.dataValue}>{props?.item?.property?.property_manager?.name}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Property Manager&apos;s Contact Number</Text>
                            <Text style={styles.colon}>:</Text>
                            <Text style={styles.dataValue}>{props?.item?.property?.property_manager?.contact_number}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Note</Text>
                        </View>
                        <View style={styles.noteField} >
                            <Text style={styles.title}>{props?.item?.note?.description}</Text>
                        </View>
                        {/* <View style={styles.footerData}>
                            <View style={styles.container}>
                                <Text style={styles.footerTitle}>Email</Text>
                                <Text style={styles.footerColon}>:</Text>
                                <Text style={styles.footerDataValue}>admin@dstayz.com</Text>
                            </View>
                            <View style={styles.container}>
                                <Text style={styles.footerTitle}>Contact</Text>
                                <Text style={styles.footerColon}>:</Text>
                                <Text style={styles.footerDataValue}>9876543210</Text>
                            </View>
                            <View style={styles.container}>
                                <Text style={styles.footerTitle}>Address</Text>
                                <Text style={styles.footerColon}>:</Text>
                                <Text style={styles.footerDataValue}>Debkripa, Plot 09 street 574 AA 2B, near Akankha More, Kolkata, </Text>
                            </View>
                        </View> */}
                    </View>
                    <View style={styles.footer}>
                        <Image src={pdfFooterBack} style={styles.footerBackground} />
                        <View style={styles.footerLeft}>
                            <View style={styles.footerRow}>
                                <Image src={pdfAddress} style={styles.footerIcon} />
                                <Text style={styles.footerText}>
                                {/* <AddressComponent address={props?.item?.property?.address} getOnlyString /> */}
                                    Debkripa, Plot 09 street 574 AA 2B, near Akankha More, Kolkata, West Bengal 700157
                                </Text>
                            </View>
                        </View>
                        <View style={styles.footerRight}>
                            <View style={styles.footerRow}>
                                <Image src={phoneIcon} style={styles.footerIcon} />
                                <Text style={styles.footerText}>6289710480</Text>
                            </View>
                            <View style={styles.footerRow}>
                                <Image src={globeIcon} style={styles.footerIcon} />
                                <Text style={styles.footerText}>admin@dstayz.com</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
};
export default SchedulePropertyVisitSlipPdf;