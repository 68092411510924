import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Box, InputLabel, MenuItem, FormControl, Select, Badge } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { unset } from 'lodash';
import { NearToDropDownPaginationList } from './NearToDropDownPaginationList';
import { getAllNearTo } from '../../api/common';

export default function Filter({ emitFilterData }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [price, setPrice] = React.useState('');
    const [bedType, setBedType] = React.useState('');

    const priceRangeArray = [
        '5000-1000'
    ]

    const handleChange = (event) => {
        console.log('avlue', event.target.value)
        setPrice(event.target.value);
    };
    const handleChangeBedType = (event) => {
        console.log('test data', event.target.value, bedType.label)
        setBedType(event.target.value);
    };

    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const [filterCount, setFilterCount] = React.useState(0); 

    const handleMultipleChange = (value) => {
        setPersonName(typeof value === 'string' ? value.split(',') : value);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const names = [
        'TCS',
        'HIDCO',
        'AMITY'
    ];

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const handleCancel = () => {
        // Clear all filter selections
        setPrice('');
        setBedType('');
        setPersonName([]);
        setFilterCount(0);
        emitFilterData()
        handleClose();
    };
    const handleApplyFilters = () => {
        const count = [
            price && 'Price',
            bedType && 'Bed Type',
            personName.length > 0 && 'Near By'
        ].filter(Boolean).length;
        
        setFilterCount(count);
        console.log('Applied Filters:', {
            minBedPrice: price,
            bedType,
            near_to: personName,
        });
        emitFilterData({
            minBedPriceRange: price,
            bedType,
            near_to: personName,
        })
        
        handleClose();
    };
    const activeFilters = [
        price && 'Price',
        bedType && 'Bed Type',
        personName.length > 0 && 'Near By'
    ].filter(Boolean).length;

    return (
        <div>
            <Button aria-describedby={id} onClick={handleClick} style={{ padding: '3px 10px'}}>
                <FilterAltOutlinedIcon /> <span className='text'>Filter</span>
                {filterCount > 0 && (
                    <Badge
                        badgeContent={filterCount}
                        color="secondary"
                        sx={{ position: 'absolute', top: 10, right: 48 }}
                    />
                )}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{ minWidth: 290, padding: 20 }} className="popContent">
                    <h4>Filters</h4>
                    <Box style={{ minWidth: '255px', margin: '15px 0' }}>
                        <FormControl fullWidth>
                            <strong>Price</strong>
                            <Select
                                id="demo-simple-select"
                                value={price}
                                onChange={handleChange}
                                size='small'
                            >
                                <MenuItem value={'5000-10000'}>₹5000 - ₹10000</MenuItem>
                                <MenuItem value={'10000-15000'}>₹10000 - ₹15000</MenuItem>
                                <MenuItem value={'15000-20000'}>₹15000 - ₹20000</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Divider style={{ borderColor: '#A8A8A8'}}/>
                    <Box style={{ minWidth: '255px', margin: '15px 0' }}>
                        <FormControl fullWidth>
                            <strong>Bed Type</strong>
                            <Select
                                id="demo-simple-select"
                                value={bedType}
                                onChange={handleChangeBedType}
                                size='small'
                            >
                                <MenuItem value={'AC Single Sharing'}>AC Single Sharing</MenuItem>
                                <MenuItem value={'AC Double Sharing'} >AC Double Sharing</MenuItem>
                                <MenuItem value={'Non AC Single Sharing'}>Non AC Single Sharing</MenuItem>
                                <MenuItem value={'Non AC Double Sharing'}>Non AC Double Sharing</MenuItem>
                                <MenuItem value={'Non AC Triple Sharing'}>Non AC Triple Sharing</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Divider style={{ borderColor: '#A8A8A8'}}/>
                    <Box style={{ minWidth: '255px', margin: '15px 0' }}>
                        <FormControl fullWidth>
                            <strong>Near By</strong>
                            <NearToDropDownPaginationList
                                searchEnabled={true}
                                label=""
                                listCall={getAllNearTo}
                                defaultValue={personName}
                                emitItem={(newValue) => {
                                    handleMultipleChange(newValue ? newValue : '')
                                }}
                                apiParams={{ limit: 10 }}
                            />
                            {/* <FormControl fullWidth>
                            <strong>Near By</strong>
                            <Select
                                id="demo-multiple-chip"
                                multiple
                                value={personName}
                                onChange={handleMultipleChange}
                                input={<OutlinedInput id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                size='small'
                                MenuProps={MenuProps}
                            >
                                {names.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, personName, theme)}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
                        </FormControl>
                    </Box>
                    <Divider style={{ borderColor: '#A8A8A8'}}/>
                    <div className='popButtons'>
                        <Button className="comm_popup_btn red_btn" onClick={handleApplyFilters}>Apply Filter</Button>
                        <Button className="comm_popup_btn grey_bordered_btn" onClick={handleCancel}>Cancel</Button>
                    </div>
                </div>
            </Popover>
        </div>
    );
}