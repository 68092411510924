import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import { Card, Grid, Button, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { EnlistRegistration } from "../../components/Common/EnlistRegistration";
import Dropzone from 'react-dropzone';
import Autocomplete from '@mui/material/Autocomplete';
import uuid from 'react-native-uuid';
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { ACTIONS, DOCUMENT_HASH_BASE_KEYS, PROPERTY_TYPES } from "../../common/constant";
import { formatBytes } from "../../helpers/string_helper";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddAmenitiesSelector from "../../components/Common/addAmenitiesSelector";
import FileList from "../../components/Common/FileList";
import { toast } from "react-toastify";
import { documentUpload } from "../../api/documents";
import { createPropertyRequest, getAllNearTo } from "../../api/new-property-request";
import ConfirmationModal from "../../components/Common/ConfirmationModal";

export const PropertyRequest = () => {
  const navigate = useNavigate();
  const stateData = useLocation().state;
  const [nearToOptions, setNearToOptions] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
    status: false,
    data: null,
  });

  const propertyRequestSchema = {
    name: '',
    owner_user_id: stateData?.user?.id,
    type: '',
    amenities: '',
    near_to: '',
    room_count: '',
    address: {
      address_line_1: '',
      address_line_2: '',
      city: '',
      district: '',
      state: '',
      country: '',
      zip_code: '',
    },
  };

  const uploadDocuments = async (formData) => {
    try {
      const response = await documentUpload(formData, stateData?.token);
      if (response.status === 200) {
        return { response, success: true };
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
      return { error, success: false };
    }
  };

  const createPropertyRequestHandler = async (propertyRequestData) => {
    try {
      const response = await createPropertyRequest(propertyRequestData, stateData?.token);
      if (response.status === 200) {
        toast.success(response.data.message);
        return response.data;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error.message);
    }
    return false;
  };

  let isSuccess = false;
  const submitHandler = async (propertyRequestData) => {
    setIsLoading(true);
    let documents
    const filesUnchanged = formik.values?.documents?.filter((file) => file?.id) ?? []
    if (filesUnchanged?.length > 0) {
      documents = [...filesUnchanged.map(sd => sd.id)]
    }

    const filesToBeUploaded = selectedFiles.filter((file) => !file?.id)
    if (filesToBeUploaded?.length > 0) {
      const formData = new FormData();
      filesToBeUploaded.forEach((f) => {
        formData.append('files', f)
      })
      formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.PROPERTY_REQUEST)
      formData.append('max_items', 10)


      const result = await uploadDocuments(formData)
      if (result.success) {
        const { response } = result

        if (documents !== undefined) {
          documents = [...documents, ...response.data.data.documents.map((doc) => doc.id)]
        } else {
          documents = [...response.data.data.documents.map((doc) => doc.id)]
        }

      } else {
        setIsLoading(false);
        return false;
      }
    }

    // removing empty fields
    propertyRequestData = {
      ...(propertyRequestData.name && {
        name: propertyRequestData.name,
      }),
      ...(propertyRequestData.owner_user_id && {
        owner_user_id: propertyRequestData.owner_user_id,
      }),
      ...(propertyRequestData.amenities && {
        amenities: propertyRequestData.amenities?.map((item) => item.name),
      }),
      ...(propertyRequestData.type && {
        type: propertyRequestData.type,
      }),
      ...(propertyRequestData.near_to && {
        near_to: propertyRequestData.near_to,
      }),
      ...(propertyRequestData.landmark && {
        landmark: propertyRequestData.landmark,
      }),
      ...(propertyRequestData.room_count && {
        room_count: propertyRequestData.room_count,
      }),
      ...(propertyRequestData.address && {
        address: {
          ...(propertyRequestData.address.address_line_1 && {
            address_line_1: propertyRequestData.address.address_line_1,
          }),
          ...(propertyRequestData.address.address_line_2 && {
            address_line_2: propertyRequestData.address.address_line_2,
          }),
          ...(propertyRequestData.address.city && {
            city: propertyRequestData.address.city,
          }),
          ...(propertyRequestData.address.district && {
            district: propertyRequestData.address.district,
          }),
          ...(propertyRequestData.address.state && {
            state: propertyRequestData.address.state,
          }),
          ...(propertyRequestData.address.country && {
            country: propertyRequestData.address.country,
          }),
          ...(propertyRequestData.address.zip_code && {
            zip_code: propertyRequestData.address.zip_code,
          }),
        },
      }),
      ...(documents === undefined ? {} : { documents })
    };
    isSuccess = await createPropertyRequestHandler(propertyRequestData);
    setIsLoading(false);
    return isSuccess;
  };

  const fetchAllNearTo = async (setNearToOptions) => {
    try {
      const res = (await getAllNearTo()).data.data
      const result = res?.data.map(item => item.name);
      setNearToOptions(result);
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error.message);
      console.log(error);
    }
  }

  useEffect(() => {
    // fetching all near to location
    fetchAllNearTo(setNearToOptions)

  }, [])

  const propertyRequestValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Property Name"),
    type: Yup.string().required('Please select a Property type'),
    near_to: Yup.string().optional('Please Enter Near To Location'),
    room_count: Yup.number().required('Please Enter Room Count'),
    address: Yup.object().shape({
      address_line_1: Yup.string().required('Please Enter Address Line 1'),
      address_line_2: Yup.string().optional('Please Enter Address Line 2'),
      city: Yup.string().required('Please Enter City'),
      district: Yup.string().required('Please Enter District'),
      state: Yup.string().required('Please Enter State'),
      country: Yup.string().required('Please Enter Country'),
      zip_code: Yup.number().required('Please Enter Zip Code'),
    })
  });


  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: propertyRequestSchema,
    validationSchema: propertyRequestValidationSchema,
    validateOnMount: true,
    onSubmit: async (propertyRequestData) => {
      const isCreated = await submitHandler(propertyRequestData)

      // Forcefully redirect to owners portal landing page if redirect url available
      if (isCreated?.data?.redirect) window.location.href = isCreated.data.redirect;
    },
  });

  const handleAddAmenities = (data) => {
    formik.setValues({
      ...formik.values,
      amenities: data
    })
  }

  const handleFormikAmenities = (itemName) => {
    const updatedAmenities = formik.values.amenities?.filter((it) => it.name !== itemName.name)
    formik.setValues({
      ...formik.values,
      amenities: updatedAmenities
    })
  }

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        uniqueId: uuid.v4(),
        location: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    formik.setValues({
      ...formik.values,
      documents: [...selectedFiles, ...files],
      total_documents_size: [...selectedFiles, ...files].reduce((p, c) => p + c.size, 0)
    }, true).then(() => {
      setselectedFiles((prev) => {
        return [...prev, ...files]
      })
    });
  }

  function confirmFileRemoval(e, file, fileIndex) {
    setIsDocumentDeleteConfirm({
      status: true,
      data: { e, file, fileIndex }
    })
  }

  function handleFileRemove(e, file, fileIndex) {
    const newDocuments = formik.values.documents.filter((doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId))
    const tempValues = {
      ...formik.values
    }

    if (newDocuments.length === 0) {
      delete tempValues.documents
      delete tempValues.total_documents_size
    }
    formik.setValues({
      ...tempValues,
      ...(
        newDocuments.length > 0 && {
          documents: newDocuments,
        }
      ),
      ...(
        newDocuments.length > 0 && {
          total_documents_size: selectedFiles.filter((doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId)).reduce((p, c) => p + c.size, 0)
        }
      ),
    }, true).then(() => {
      setselectedFiles(prev => prev.filter((_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId)))
    });

    return true
  }

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: 'red', // Change the color here
          },
        },
      },
    },
  });

  useEffect(() => {
    // Show form only if redirected after otp validation 
    if (!stateData?.user) navigate('/')
  }, [stateData]);
  return (
    <div className='manage_enlist_property'>
      <section>
        <Container>
          <div className="property_details">
            <EnlistRegistration />
            <div className="property_form_section">
              <Card className="p-3">
                <h2>Manage your properties, <span>maximize your profits.</span></h2>
                <h5>Get Started</h5>
                <Grid container spacing={2}>
                  {/* Form Fields */}
                  {/* Name */}
                  <Grid item xs={12}>
                    <ThemeProvider theme={theme}>

                      <TextField
                        className='form-control'
                        type='text'
                        label='Name'
                        size='small'
                        name='name'

                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.name || ''}
                        error={!!(formik?.touched?.name && formik?.errors?.name)}
                        helperText={formik?.touched?.name && formik?.errors?.name ? formik?.errors?.name : ''}
                        required
                      />
                    </ThemeProvider>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="custom_select">
                      <Autocomplete
                        autoHighlight
                        name='near_to'
                        size='small'
                        value={formik.values?.near_to}
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            'near_to',
                            newValue ? newValue : ''
                          );
                        }}
                        onBlur={() => {
                          if (!formik.touched?.near_to) {
                            formik.setFieldTouched('near_to', true, true);
                          }
                        }}
                        renderOption={(props, option) => (
                          <Box component='li' key={option} {...props}>
                            {option}
                          </Box>
                        )}
                        isOptionEqualToValue={(option, value) => option === value}
                        options={nearToOptions}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              onBlur={formik.handleBlur}
                              error={!!(formik?.touched?.near_to && formik?.errors?.near_to)}
                              helperText={(formik?.touched?.near_to && formik?.errors?.near_to) ? formik?.errors?.near_to : ''}
                              label='Near To'
                              size='small'
                            />
                          );
                        }}
                      />
                      <div>
                        {formik.touched.near_to && formik.errors.near_to && (
                          <span className="text-danger">{formik.errors.near_to}</span>
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="custom_select">
                      {/* Property Type */}
                      <Autocomplete
                        name='type'
                        size='small'
                        value={formik.values?.type}
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            'type',
                            newValue ? newValue : ''
                          );
                        }}
                        onBlur={() => {
                          if (!formik.touched?.type) {
                            formik.setFieldTouched('type', true, true);
                          }
                        }}
                        renderOption={(props, option) => (
                          <Box component='li' key={option} {...props}>
                            {option}
                          </Box>
                        )}
                        isOptionEqualToValue={(option, value) => option === value}
                        options={Object.values(PROPERTY_TYPES)}
                        renderInput={(params) => {
                          return (
                            <ThemeProvider theme={theme}>
                              <TextField
                                {...params}
                                onBlur={formik.handleBlur}
                                error={!!(formik?.touched?.type && formik?.errors?.type)}
                                helperText={(formik?.touched?.type && formik?.errors?.type) ? formik?.errors?.type : ''}
                                label='Select Property Type'
                                size='small'
                                required
                              />
                            </ThemeProvider>
                          );
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="custom_select">
                      <TextField
                        className='form-control'
                        type='text'
                        label='Landmark'
                        name='landmark'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.landmark || ''}
                        error={
                          !!(formik?.touched?.landmark && formik?.errors?.landmark)
                        }
                        helperText={
                          formik?.touched?.landmark && formik?.errors?.landmark
                            ? formik?.errors?.landmark
                            : ''
                        }
                        size='small'
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="custom_select">
                      <ThemeProvider theme={theme}>
                        <TextField
                          size='small'
                          className='form-control'
                          type='text'
                          label='Room Count'
                          name='room_count'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik?.values?.room_count}
                          error={!!(formik?.touched?.room_count && formik?.errors?.room_count)}
                          helperText={(formik?.touched?.room_count && formik?.errors?.room_count) ? formik?.errors?.room_count : ''}
                          required
                        />
                      </ThemeProvider>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <ThemeProvider theme={theme}>
                      <TextField
                        className='form-control'
                        type='text'
                        label='Address Line 1'
                        name='address.address_line_1'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.address?.address_line_1 || ''}
                        error={
                          !!(formik?.touched?.address?.address_line_1 && formik?.errors?.address?.address_line_1)
                        }
                        helperText={
                          formik?.touched?.address?.address_line_1 && formik?.errors?.address?.address_line_1
                            ? formik?.errors?.address?.address_line_1
                            : ''
                        }
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className='form-control'
                      type='text'
                      label='Address Line 2'
                      name='address.address_line_2'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.address?.address_line_2 || ''}
                      error={
                        !!(formik?.touched?.address?.address_line_2 && formik?.errors?.address?.address_line_2)
                      }
                      helperText={
                        formik?.touched?.address?.address_line_2 && formik?.errors?.address?.address_line_2
                          ? formik?.errors?.address?.address_line_2
                          : ''
                      }
                      size='small'
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <ThemeProvider theme={theme}>
                      <TextField
                        className='form-control'
                        type='text'
                        label='City'
                        name='address.city'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.address?.city || ''}
                        error={
                          !!(formik?.touched?.address?.city && formik?.errors?.address?.city)
                        }
                        helperText={
                          formik?.touched?.address?.city && formik?.errors?.address?.city
                            ? formik?.errors?.address?.city
                            : ''
                        }
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <ThemeProvider theme={theme}>
                      <TextField
                        className='form-control'
                        type='text'
                        label='District'
                        name='address.district'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.address?.district || ''}
                        error={
                          !!(formik?.touched?.address?.district && formik?.errors?.address?.district)
                        }
                        helperText={
                          formik?.touched?.address?.district && formik?.errors?.address?.district
                            ? formik?.errors?.address?.district
                            : ''
                        }
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <ThemeProvider theme={theme}>
                      <TextField
                        className='form-control'
                        type='text'
                        label='State'
                        name='address.state'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.address?.state || ''}
                        error={
                          !!(formik?.touched?.address?.state && formik?.errors?.address?.state)
                        }
                        helperText={
                          formik?.touched?.address?.state && formik?.errors?.address?.state
                            ? formik?.errors?.address?.state
                            : ''
                        }
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <ThemeProvider theme={theme}>
                      <TextField
                        className='form-control'
                        type='text'
                        label='Country'
                        name='address.country'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.address?.country || ''}
                        error={
                          !!(formik?.touched?.address?.country && formik?.errors?.address?.country)
                        }
                        helperText={
                          formik?.touched?.address?.country && formik?.errors?.address?.country
                            ? formik?.errors?.address?.country
                            : ''
                        }
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <ThemeProvider theme={theme}>
                      <TextField
                        className='form-control'
                        type='text'
                        label='Zip Code'
                        name='address.zip_code'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.address?.zip_code || ''}
                        error={
                          !!(formik?.touched?.address?.zip_code && formik?.errors?.address?.zip_code)
                        }
                        helperText={
                          formik?.touched?.address?.zip_code && formik?.errors?.address?.zip_code
                            ? formik?.errors?.address?.zip_code
                            : ''
                        }
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <AddAmenitiesSelector
                      handleAddAmenities={handleAddAmenities}
                      selectedAmenities={formik.values?.amenities}
                      handleFormikAmenities={handleFormikAmenities}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className='col-12'>
                      <label>Upload Documents</label>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                        }}>
                        {({ getRootProps, getInputProps }) => (
                          <div className='dropzone' style={{
                            background: '#f0fcff',
                            marginBottom: '10px',
                            border: '2px dashed #69ceee',
                            borderRadius: '15px',
                          }}>
                            <div className='dz-message needsclick' {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className='mb-3'>
                                <i className='display-4 text-muted uil uil-cloud-upload' />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      {
                        selectedFiles.length > 0 && (
                          <FileList
                            files={selectedFiles}
                            confirmFileRemoval={confirmFileRemoval}
                          />
                        )
                      }
                    </div>
                  </Grid>
                </Grid>
                <div className="payment_btn payment_area book_payment_btn mt-3">
                  <Button
                    className={`btn ${isLoading || !formik.isValid ? 'theme_button sign_in_button' : 'theme_button sign_in_button'} waves-effect waves-light px-4`}
                    variant="contained"
                    onClick={formik.handleSubmit}
                    type="button"
                    data-dismiss="modal"
                    size='small'
                    style={{ cursor: isLoading || !formik.isValid ? 'not-allowed' : 'pointer' }}
                    disabled={isLoading || !formik.isValid}
                  >
                    Add Property
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </Container>
      </section>

      {/* Document Delete confirmation modal */}
      {isDocumentDeleteConfirm?.status &&
        <ConfirmationModal
          action={ACTIONS.DELETE}
          show={isDocumentDeleteConfirm?.status}
          onCloseClick={() =>
            setIsDocumentDeleteConfirm({ status: false, data: null })
          }
          onAcceptClick={async () => {
            const { e, file, fileIndex } = isDocumentDeleteConfirm.data
            const isDeleted = handleFileRemove(
              e, file, fileIndex
            );
            if (isDeleted) {
              setIsDocumentDeleteConfirm({ status: false, data: null });
            }
          }}
          isDisabled={isLoading}
        />}
    </div>
  );
};
